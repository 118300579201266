import { Component, ViewChild, ElementRef, AfterViewInit, OnInit, ViewEncapsulation } from '@angular/core';
import { LlModelsService } from '../ll-models.service';
import { LlScrollpaneService } from '../ll-scrollpane.service';
import { LlPermissionsService } from '../ll-permissions.service';
import { jqxPanelComponent } from 'jqwidgets-scripts/jqwidgets-ts/angular_jqxpanel'
import { jqxPopoverComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxpopover";
import { jqxTextAreaComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxtextarea";
import { jqxDateTimeInputComponent } from "jqwidgets-scripts/jqwidgets-ts/angular_jqxdatetimeinput";
import { fromEvent } from 'rxjs';
import { filter } from "../utility-search";
///import { CCYYMMDD_to_ISODate } from "../utility-date";
import { removeCarriageReturn } from "../utility";

@Component({
  selector: 'll-model-reports-sp',
  templateUrl: './ll-model-reports-sp.component.html',
  styleUrls: ['../cssMain.css'],
  ///styles: ['.jqx-panel .jqx-widget-content { background-color: transparent;}'],

  encapsulation: ViewEncapsulation.None
})

export class LlModelReportsSpComponent implements OnInit {

  @ViewChild('mainDiv_ll_model_reports_sp') mainDiv: ElementRef;
  @ViewChild('vertTab_ll_model_reports_sp') private vertTab: ElementRef;
  @ViewChild('scrollpane_ll_model_reports_sp') private SP: jqxPanelComponent;
  @ViewChild('popover_search') private popover_search: jqxPopoverComponent;
  @ViewChild('text_search') text_search: jqxTextAreaComponent;
  //@ViewChild('horzTabs_ll_model_reports_sp', { static: false }) public horzTabs: ElementRef;
  @ViewChild('popover_classCo') private popover_classCo: jqxPopoverComponent;
  @ViewChild('text_className') text_className: jqxTextAreaComponent;
  @ViewChild('date_THROUGHDATE') date_THROUGHDATE: jqxDateTimeInputComponent;

  constructor(
    private LlModelsService: LlModelsService,
    private LlScrollpaneService: LlScrollpaneService,
    private LlPermissionsService: LlPermissionsService,

  ) { }

  bundle = {
    lable: "Reports",
    prefix: "MR",
    selectedId: 0,
    selectedId_ondeck: 0,
    //showObj: false,
    showObj: true,
    showRemaining: false,
    scrolling: false,
    canAdd: false,
    canEdit: false,
    canFilter: false,
    searchText: "",
    THROUGHDATE_NUM: '',
    //THROUGHDATE_NUM_Origional: '',
    THROUGHDATE_DATE: '',
    LlModelsService: this.LlModelsService,
    date_THROUGHDATE: {},/// this.date_THROUGHDATE,
    records: [],
    origionalRecords: [],
    activeView:'',
    parentW: 0,
    parentH: 0,
    remainingX: 0,
    remainingY: 0,
    remainingW: 0,
    remainingH: 0,
    SPYPosition: 0,
    throughDateH:30,
    ctx: {},
    showTab: true,
    objH: 0,
    lblY: 20,//40,//20,
    topOffset: 0,
    spY: 0,
    spW: 150,
    spH: 0,
    spB: 0,
    lineWidth: 1,
    vertLineX: 0,
    vertLineY: -1,
    vertLineW: 20,
    vertLineH: 0,
    tabY: 0,
    tabW: 0,
    tabH: 18,
    hTabW: 100,
    hTabH: 25,
    hTabIndx: 0,
    horzTabs: [],
    canvasHT: {},
    ctxHT: {},
  }

  ngOnInit() {}
  ngOnDestroy() {}

  ngAfterViewInit() {

    fromEvent(this.mainDiv.nativeElement, 'mousemove')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );

    fromEvent(this.mainDiv.nativeElement, 'wheel')
      .subscribe(res => {
        let value = this.SP.getVScrollPosition();

        if (value != this.bundle.SPYPosition) {
          this.bundle.SPYPosition = value;

          this.bundle.scrolling = true;

          document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
        }
      }
      );
       
    this.setVars();
  }

  setVars() {

    this.bundle.date_THROUGHDATE = this.date_THROUGHDATE;

    this.bundle.parentW = this.mainDiv.nativeElement.parentElement.parentElement.clientWidth;
    this.bundle.parentH = this.mainDiv.nativeElement.parentElement.parentElement.clientHeight;

    setTimeout(() => {

      this.LlScrollpaneService.standardDimentions(this.bundle);

      var canvasEl: HTMLCanvasElement = this.vertTab.nativeElement;
      canvasEl.width = this.bundle.vertLineX + this.bundle.vertLineW;
      canvasEl.height = this.bundle.vertLineH - 0;
      this.bundle.ctx = canvasEl.getContext('2d');

      setTimeout(() => {

        this.LlScrollpaneService.standardRemainderValues(this.bundle, canvasEl);

        this.bundle.remainingY = this.bundle.throughDateH + 10
        this.bundle.remainingH = this.bundle.remainingH - this.bundle.remainingY

        this.getData();
      });
    });
  }

  getData() {

    this.bundle.THROUGHDATE_NUM = this.LlModelsService.getThroughDate(); ///THROUGHDATE_NUM;

    if (this.bundle.THROUGHDATE_NUM == null || this.bundle.THROUGHDATE_NUM == '') {
      this.LlModelsService.NG_LL_model_AR_retreiveThroughDate(done01, this.bundle);
    } else {

      done01(this.bundle.THROUGHDATE_NUM, this.bundle);
    }    

    function done01(THROUGHDATE_NUM, bundle) {

      bundle.THROUGHDATE_NUM = THROUGHDATE_NUM;
      bundle.LlModelsService.NG_LL_model_AR_reports_list_get(done02, 'MODEL', bundle);
    }
    function done02(records, bundle) {

      bundle.records = records;
      bundle.origionalRecords = records;

      
   
      let dateNumText = String(bundle.THROUGHDATE_NUM);
      var year = Number(dateNumText.substring(0, 4));
      var month = Number(dateNumText.substring(4, 6)) - 1;
      var day = Number(dateNumText.substring(6, 8));

      bundle.date_THROUGHDATE.setDate(new Date(year, month, day));
      //bundle.date_THROUGHDATE.setMaxDate(new Date(year, month, day));

      let selectedId_initial = bundle.LlModelsService.getActiveSYSTEMOPERATIONREPORTSID();

      setTimeout(() => {

        bundle.showObj = true;

        setTimeout(() => {

          if (selectedId_initial != null && document.getElementById(bundle.prefix + selectedId_initial.toString()) != null) {
            document.getElementById(bundle.prefix + selectedId_initial.toString()).click();
          }

        });
      });
    }
  }

  select(event) {

    this.bundle = this.LlScrollpaneService.bundle(event, this.bundle, this.SP)

    this.LlScrollpaneService.paintVertTab(this.bundle);

    if (this.bundle.scrolling == true) {

      this.bundle.scrolling = false;

    } else {

      this.bundle.showRemaining = false;

      setTimeout(() => {

        this.bundle.selectedId = this.bundle.selectedId_ondeck;

        this.LlModelsService.setActiveSYSTEMOPERATIONREPORTSID(this.bundle.selectedId);
               
        var report = this.bundle.LlModelsService.getActiveReport();

        this.bundle.activeView = report.VISITCATEGORY.toLowerCase()

        this.bundle.showRemaining = true;

        
      });
    }
  }

  noAction() {
    //needed HTML buttons point to this
  }

  onKeyUp(event) {

    if (event.keyCode == 13) { ///Enter
      this.text_search.val(removeCarriageReturn(this.text_search.val()));
      this.search();
    }

    if (event.keyCode == 9) { ///Tab || Enter
      this.search();
    }
  }

  search() {

    this.popover_search.close()

    setTimeout(() => {
      this.bundle.searchText = this.text_search.val();

      if (this.bundle.searchText == "") {
        this.bundle.records = this.bundle.origionalRecords;
      } else {
        this.bundle.records = filter(this.bundle.origionalRecords, "FOLDERNAME", this.bundle.searchText);

        if (this.bundle.records.length > 0) {
          this.bundle.selectedId = this.bundle.records[0].FOLDERID;

          this.LlModelsService.setPayerClass(this.bundle.selectedId);

        }
      }

      this.searchClick();
    });
  }

  clear() {
    this.bundle.records = this.bundle.origionalRecords;

    ///console.log(this.bundle.records)

    this.bundle.searchText = ""
    this.text_search.val("");
    let looking = true;

    for (var i = 0; i < this.bundle.records.length; i++) {

      if (this.bundle.records[i].FOLDERID > 0 && looking == true) {

        looking = false;

        this.popover_search.close()

        this.bundle.selectedId = this.bundle.records[i].FOLDERID;

        this.LlModelsService.setPayerClass(this.bundle.selectedId);

        setTimeout(() => {

          //console.log("this.bundle.selectedId: " + this.bundle.selectedId)

          if (this.bundle.selectedId != null && document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()) != null) {
            document.getElementById(this.bundle.prefix + this.bundle.selectedId.toString()).click();
          }
        }, 200);
      }
    }
  }

  searchClick() {


    if (this.bundle.records.length == 0) {

      this.bundle.showTab = false;
      this.bundle.selectedId = -666

      this.LlScrollpaneService.paintVertTab(this.bundle);

    } else {

      let selectedId_local = this.bundle.records[0].FOLDERID;

      setTimeout(() => {

        if (selectedId_local != null && document.getElementById(this.bundle.prefix + selectedId_local.toString()) != null) {

          document.getElementById(this.bundle.prefix + selectedId_local.toString()).click();
        }

      });
    }

  }

  add() {

    if (this.text_className.val() != "") {

      this.popover_classCo.close();

      this.LlModelsService.NG_LL_model_AR_payerClasses_addEdit('ADD', this.text_className.val(), this.bundle);
    }
  }

  selectThroughDate() {

    ///console.log("selectThroughDate()")

    this.bundle.showRemaining = false;

    let throughDate_date = String(this.date_THROUGHDATE.val())

    var myArray = throughDate_date.split("/");

    var day = myArray[1];

    if (Number(day) < 10) {
      day = "0" + day;
    }

    var month = myArray[0];

    if (Number(month) < 10) {
      month = "0" + month;
    }

    var year = myArray[2]

    let throughDate_num = year + "" + month + "" + day;

    let THROUGHDATE_MAX = this.LlModelsService.getThroughDate_MAX() 

    if (Number(throughDate_num) > Number(THROUGHDATE_MAX)) {
      this.bundle.THROUGHDATE_NUM = THROUGHDATE_MAX;

    } else {

      this.bundle.THROUGHDATE_NUM = throughDate_num;
      this.LlModelsService.setThroughDate_num(throughDate_num)

    }

    let dateNumText = String(this.bundle.THROUGHDATE_NUM);
    var year2 = Number(dateNumText.substring(0, 4));
    var month2 = Number(dateNumText.substring(4, 6)) - 1;
    var day2 = Number(dateNumText.substring(6, 8));
    this.date_THROUGHDATE.setDate(new Date(year2, month2, day2));

    //this.bundle.THROUGHDATE_DATE = CCYYMMDD_to_ISODate(String(this.bundle.THROUGHDATE_NUM))
    //this.date_THROUGHDATE.val(this.bundle.THROUGHDATE_DATE)

    setTimeout(() => {
      this.bundle.showRemaining = true;
    });
  }


}


